import moment from 'moment';
import React from 'react';
import HeaderlaporanMail from '../general/HeaderLaporanMail';
import TimelineSatpamProses from '../general/TimelineSatpamProses';

const TableCetakTemuanMail = ({ data, date }) => {
  return (
    <div style={{ width: '100%' }}>
      {data.map((item) => (
        <div key={item.satpamtemuankode}>
          <div style={{ display: 'flex', width: '100%', padding: '0.5rem 0' }}>
            <HeaderlaporanMail />
            <div
              style={{
                width: ' 100%',
                padding: '0.7rem',
                textAlign: 'right',
                backgroundColor: '#0f172a',
                textColor: 'white'
              }}>
              <div
                style={{
                  display: 'block',
                  fontSize: '18px',
                  fontWeight: '700',
                  color: 'rgb(243, 244, 246)'
                }}>
                LAPORAN TEMUAN
              </div>
              <div
                style={{
                  display: 'block',
                  fontSize: '15px',
                  fontWeight: '700',
                  color: 'rgb(243, 244, 246)'
                }}>
                {data.satpamperusahaannama}
              </div>
            </div>
          </div>
          <div style={{ margin: '1.25rem' }}>
            <table
              style={{ width: '100%', borderColor: 'rgb(249, 250, 251)' }}
              id="kegiatanHarianPrint">
              <thead
                style={{
                  backgroundColor: 'rgb(5, 45, 90)',
                  fontSize: '12px',
                  fontWeight: '700',
                  color: 'rgb(255, 255, 255)'
                }}>
                <tr>
                  <th
                    colSpan="7"
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      textAlign: 'left'
                    }}>
                    <div style={{ padding: '0.5rem', fontSize: '18px', fontWeight: '600' }}>
                      #{item?.satpamtemuankode}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody style={{ color: 'rgb(0, 0, 0)' }}>
                <tr>
                  <td
                    style={{
                      width: '30%',
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      fontWeight: '600'
                    }}>
                    {moment(item?.satpamtemuantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
                  </td>
                  <td
                    colSpan="4"
                    rowSpan="4"
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <h4 style={{ fontWeight: '700' }}>DETAIL TEMUAN</h4>
                    <p style={{ marginBottom: ' 0.5rem' }}>{item?.satpamtemuanuraian}</p>
                    <h4 style={{ fontWeight: '700' }}>TINDAKAN</h4>
                    <p style={{ marginBottom: ' 0.5rem' }}>{item?.satpamtemuantindakan}</p>
                    <h4 style={{ fontWeight: '700' }}>HASIL TINDAKAN</h4>
                    <p>{item?.satpamtemuanhasil}</p>
                  </td>
                  <td
                    rowSpan="5"
                    style={{
                      width: '20%',
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <h4 style={{ marginBottom: '0.5rem', fontWeight: '600' }}>DETAIL PROSES</h4>
                    <TimelineSatpamProses data={item?.satpam_proses ? item?.satpam_proses : ''} />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <span style={{ fontWeight: '700' }}>PATOKAN </span>:{' '}
                    {item?.satpamtemuanalamatpatokan}
                    <br />
                    <span style={{ fontWeight: '700' }}>ALAMAT LENGKAP</span> :{' '}
                    {item?.satpamtemuanalamat}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top',
                      width: '25%'
                    }}>
                    <p style={{ fontWeight: '700' }}>KATEGORI :</p>
                    <p>{item?.satpamtemuankategorinama}</p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <h4 style={{ fontWeight: '700' }}>PELAPOR : {item?.satpamnamalengkap}</h4>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="5"
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '1.25rem'
                    }}>
                    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <div
                        style={{
                          margin: '-0.25rem',
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}>
                        {item?.satpamtemuanfotosatu?.split('/data-temuan/')[1] && (
                          <div style={{ display: 'flex', width: '20%', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%', padding: '0.25rem' }}>
                              <div
                                style={{
                                  maxWidth: '24rem',
                                  wordBreak: 'break-all',
                                  borderRadius: '0.375rem',
                                  borderTopLeftRadius: '0.375rem',
                                  borderTopRightRadius: '0.375rem',
                                  borderWidth: '1px',
                                  borderColor: 'rgb(229, 231, 235)',
                                  backgroundColor: 'rgb(255, 255, 255)',
                                  boxShadow:
                                    '0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -2px rgb(0, 0, 0, 0.1)'
                                }}>
                                <img
                                  style={{ height: '9rem', width: '100%', objectFit: 'contain' }}
                                  src={item?.satpamtemuanfotosatu}
                                  alt=""
                                />
                                <div style={{ padding: '0.75rem' }}>
                                  <p style={{ marginBottom: '0.5rem' }}>
                                    {item?.satpamtemuanketeranganfotosatu}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item?.satpamtemuanfotodua?.split('/data-temuan/')[1] && (
                          <div style={{ display: 'flex', width: '20%', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%', padding: '0.25rem' }}>
                              <div
                                style={{
                                  maxWidth: '24rem',
                                  wordBreak: 'break-all',
                                  borderRadius: '0.375rem',
                                  borderTopLeftRadius: '0.375rem',
                                  borderTopRightRadius: '0.375rem',
                                  borderWidth: '1px',
                                  borderColor: 'rgb(229, 231, 235)',
                                  backgroundColor: 'rgb(255, 255, 255)',
                                  boxShadow:
                                    '0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -2px rgb(0, 0, 0, 0.1)'
                                }}>
                                <img
                                  style={{ height: '9rem', width: '100%', objectFit: 'contain' }}
                                  src={item?.satpamtemuanfotodua}
                                  alt=""
                                />
                                <div style={{ padding: '0.75rem' }}>
                                  <p style={{ marginBottom: '0.5rem' }}>
                                    {item?.satpamtemuanketeranganfotodua}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item?.satpamtemuanfototiga?.split('/data-temuan/')[1] && (
                          <div style={{ display: 'flex', width: '20%', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%', padding: '0.25rem' }}>
                              <div
                                style={{
                                  maxWidth: '24rem',
                                  wordBreak: 'break-all',
                                  borderRadius: '0.375rem',
                                  borderTopLeftRadius: '0.375rem',
                                  borderTopRightRadius: '0.375rem',
                                  borderWidth: '1px',
                                  borderColor: 'rgb(229, 231, 235)',
                                  backgroundColor: 'rgb(255, 255, 255)',
                                  boxShadow:
                                    '0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -2px rgb(0, 0, 0, 0.1)'
                                }}>
                                <img
                                  style={{ height: '9rem', width: '100%', objectFit: 'contain' }}
                                  src={item?.satpamtemuanfototiga}
                                  alt=""
                                />
                                <div style={{ padding: '0.75rem' }}>
                                  <p style={{ marginBottom: '0.5rem' }}>
                                    {item?.satpamtemuanketeranganfototiga}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item?.satpamtemuanfotoempat?.split('/data-temuan/')[1] && (
                          <div style={{ display: 'flex', width: '20%', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%', padding: '0.25rem' }}>
                              <div
                                style={{
                                  maxWidth: '24rem',
                                  wordBreak: 'break-all',
                                  borderRadius: '0.375rem',
                                  borderTopLeftRadius: '0.375rem',
                                  borderTopRightRadius: '0.375rem',
                                  borderWidth: '1px',
                                  borderColor: 'rgb(229, 231, 235)',
                                  backgroundColor: 'rgb(255, 255, 255)',
                                  boxShadow:
                                    '0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -2px rgb(0, 0, 0, 0.1)'
                                }}>
                                <img
                                  style={{ height: '9rem', width: '100%', objectFit: 'contain' }}
                                  src={item?.satpamtemuanfotoempat}
                                  alt=""
                                />
                                <div style={{ padding: '0.75rem' }}>
                                  <p style={{ marginBottom: '0.5rem' }}>
                                    {item?.satpamtemuanketeranganfotoempat}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item?.satpamtemuanfotolima?.split('/data-temuan/')[1] && (
                          <div style={{ display: 'flex', width: '20%', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%', padding: '0.25rem' }}>
                              <div
                                style={{
                                  maxWidth: '24rem',
                                  wordBreak: 'break-all',
                                  borderRadius: '0.375rem',
                                  borderTopLeftRadius: '0.375rem',
                                  borderTopRightRadius: '0.375rem',
                                  borderWidth: '1px',
                                  borderColor: 'rgb(229, 231, 235)',
                                  backgroundColor: 'rgb(255, 255, 255)',
                                  boxShadow:
                                    '0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0, 0, 0, 0.1), 0 2px 4px -2px rgb(0, 0, 0, 0.1)'
                                }}>
                                <img
                                  style={{ height: '9rem', width: '100%', objectFit: 'contain' }}
                                  src={item?.satpamtemuanfotolima}
                                  alt=""
                                />
                                <div style={{ padding: '0.75rem' }}>
                                  <p style={{ marginBottom: '0.5rem' }}>
                                    {item?.satpamtemuanketeranganfotolima}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="w-full">
                  <td
                    colSpan="3"
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <p style={{ fontWeight: '700' }}>POTENSI TEMUAN :</p>
                    <p>
                      {item?.satpamtemuanpotensi !== null
                        ? item?.satpamtemuanpotensi
                        : 'Belum ada potensi'}
                    </p>
                    <p style={{ fontWeight: '700', marginTop: '0.5rem' }}>ANALISIS TEMUAN :</p>
                    <p>
                      {item?.satpamtemuananalisis !== null
                        ? item?.satpamtemuananalisis
                        : 'Belum ada analisis'}
                    </p>
                  </td>
                  <td
                    colSpan="3"
                    style={{
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'left',
                      verticalAlign: 'top'
                    }}>
                    <p style={{ fontWeight: '700' }}>KESIMPULAN TEMUAN :</p>
                    <p>
                      {item?.satpamtemuankesimpulan !== null
                        ? item?.satpamtemuankesimpulan
                        : 'Belum ada kesimpulan'}
                    </p>
                    <p style={{ fontWeight: '700', marginTop: ' 0.5rem' }}>
                      SARAN DAN REKOMENDASI :
                    </p>
                    <p>
                      {item?.satpamtemuanrekomendasi !== null
                        ? item?.satpamtemuanrekomendasi
                        : 'Belum ada rekomendasi'}
                    </p>
                  </td>
                  <td
                    style={{
                      width: '20%',
                      borderWidth: '1px',
                      borderColor: 'rgb(209, 213, 219)',
                      padding: '0.5rem',
                      textAlign: 'center',
                      verticalAlign: 'middle'
                    }}>
                    <div>Tanda Tangan</div>
                    <div
                      style={{
                        marginLeft: '1.25rem',
                        marginRight: '1.25rem',
                        marginTop: '7rem',
                        marginBottom: '2.5rem',
                        border: '1px solid #c0c0c0'
                      }}></div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                width: '100%',
                textAlign: 'center',
                color: 'rgb(0, 0, 0)'
              }}>
              <b>Copyright@{moment().year()}</b>
              <br />
              <b>PT. Kalpika Loka Persada</b>
            </div>
          </div>
          <div className="page-break" />
        </div>
      ))}
    </div>
  );
};

export default TableCetakTemuanMail;
