import moment from 'moment';
import React from 'react';
import { parseToNewLine } from '../../utils/parseToNewLine';
import Headerlaporan from '../general/HeaderLaporan';
import TimelineSatpamProses from '../general/TimelineSatpamProses';

const TableCetakKegiatan = ({ data, date }) => {
  const dateNow = moment().year;
  return (
    <div className="w-full">
      {data.map((item) => (
        <div key={item.satpamkegiatanid}>
          {typeof date !== 'undefined' && (
            <h4 className="text-md p-2">
              <span className="font-bold">Periode : </span>
              {moment(date.startDate).format('DD MMM YYYY')} s.d{' '}
              {moment(date.endDate).format('DD MMM YYYY')}
            </h4>
          )}
          <div className="flex w-full">
            <Headerlaporan />
            <div className="w-full px-5 bg-dark flex flex-col items-end justify-center text-white">
              <div className="text-gray-100 text-[18px] font-bold">LAPORAN KEGIATAN HARIAN</div>
              <div className="text-gray-100 text-[15px] font-bold">{item.satpamperusahaannama}</div>
            </div>
          </div>
          <div className="m-5">
            <table className="border-gray-50" id="kegiatanHarianPrint">
              <thead className="bg-primary font-bold text-white text-[12px]">
                <tr>
                  <th colSpan="4" className="border border-gray-300 px-2 py-2 text-left">
                    <p className="font-semibold text-[18px]">#{item?.satpamkegiatankode}</p>
                  </th>
                </tr>
              </thead>
              <tbody className="text-black">
                <tr>
                  <td className="w-[30%] border border-gray-300 px-2 py-2 text-left text-primary font-semibold">
                    {moment(item?.satpamkegiatantanggalmelaporkan).format('DD MMM YYYY HH:mm')}
                  </td>
                  <td className="border border-gray-300 px-2 py-2 text-left">
                    <span className="font-semibold">KATEGORI</span> :{' '}
                    {item?.satpamkegiatankategorinama}
                  </td>
                  <td
                    rowSpan="3"
                    className="w-[20%] border border-gray-300 px-2 py-2 text-left align-top">
                    <h4 className="font-semibold mb-2">DETAIL PROSES</h4>
                    <TimelineSatpamProses data={item.satpam_proses ? item.satpam_proses : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-2 py-2 text-left align-top">
                    <span className="font-semibold">PATOKAN LOKASI</span> :{' '}
                    {item?.satpamkegiatanalamatpatokan}
                    <br /> <br />
                    <span className="font-semibold">ALAMAT LENGKAP</span> :{' '}
                    {item?.satpamkegiatanalamat}
                  </td>
                  <td rowSpan="2" className="border border-gray-300 px-2 py-2 text-left align-top">
                    <h4 className="font-semibold mb-2">URAIAN KEGIATAN</h4>
                    <p className="mb-3">{parseToNewLine(item?.satpamkegiatanuraian)}</p>
                    <h4 className="font-semibold mb-2">KETERANGAN</h4>
                    {parseToNewLine(item?.satpamkegiatanketerangan)}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-2 py-2 text-left">
                    <h4 className="text-md">
                      <span className="font-semibold">PELAPOR</span> <br />{' '}
                      {item?.satpamnamalengkap}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" rowSpan="2" className="border border-gray-300 p-5">
                    <div className="mx-auto">
                      <div className="flex flex-wrap -m-1 md:-m-2">
                        {item.satpamkegiatanfotosatu.split('/data-kegiatan/')[1] && (
                          <div className="flex flex-wrap w-1/5">
                            <div className="w-full p-1 md:p-2">
                              <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all rounded-t-md ">
                                <img
                                  className="h-36 object-contain w-full"
                                  src={item?.satpamkegiatanfotosatu}
                                  alt=""
                                />
                                <div className="p-1">
                                  <p className="mb-2 text-xs">
                                    {item?.satpamkegiatanketeranganfotosatu}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item.satpamkegiatanfotodua.split('/data-kegiatan/')[1] && (
                          <div className="flex flex-wrap w-1/5">
                            <div className="w-full p-1 md:p-2">
                              <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                                <img
                                  className="rounded-t-md h-36 object-contain w-full"
                                  src={item?.satpamkegiatanfotodua}
                                  alt=""
                                />
                                <div className="p-1">
                                  <p className="mb-2 text-xs">
                                    {item?.satpamkegiatanketeranganfotodua}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item.satpamkegiatanfototiga.split('/data-kegiatan/')[1] && (
                          <div className="flex flex-wrap w-1/5">
                            <div className="w-full p-1 md:p-2">
                              <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                                <img
                                  className="rounded-t-md h-36 object-contain w-full"
                                  src={item?.satpamkegiatanfototiga}
                                  alt=""
                                />
                                <div className="p-1">
                                  <p className="mb-2 text-xs">
                                    {item?.satpamkegiatanketeranganfototiga}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item.satpamkegiatanfotoempat.split('/data-kegiatan/')[1] && (
                          <div className="flex flex-wrap w-1/5">
                            <div className="w-full p-1 md:p-2">
                              <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                                <img
                                  className="rounded-t-md h-36 object-contain w-full"
                                  src={item?.satpamkegiatanfotoempat}
                                  alt=""
                                />
                                <div className="p-1">
                                  <p className="mb-2 text-xs">
                                    {item?.satpamkegiatanketeranganfotoempat}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {item.satpamkegiatanfotolima.split('/data-kegiatan/')[1] && (
                          <div className="flex flex-wrap w-1/5">
                            <div className="w-full p-1 md:p-2">
                              <div className="min-h-52 max-w-sm bg-white rounded-md border border-gray-200 shadow-md break-all">
                                <img
                                  className="rounded-t-md h-36 object-contain w-full"
                                  src={item?.satpamkegiatanfotolima}
                                  alt=""
                                />
                                <div className="p-1">
                                  <p className="mb-2 text-xs">
                                    {item?.satpamkegiatanketeranganfotolima}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="w-[20%] border border-gray-300 px-2 py-2 text-center align-middle">
                    <div>Tanda Tangan</div>
                    <div className="border mt-28 mb-10 mx-5" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-center w-full my-2 text-black">
            <b>Copyright@{dateNow}</b>
            <br />
            <b>PT. Kalpika Loka Persada</b>
          </div>
          <div className="page-break" />
        </div>
      ))}
    </div>
  );
};

export default TableCetakKegiatan;
