// Import packages
import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
// Import files
import Grafik from '../../components/general/Grafik';
import MapBox from '../../components/dashboard/MapBox';
import { detectDevice } from '../../config/usedetectDevice';

export default function Dashboard({ satpamData }) {
  // Data tabel
  const [dataKegiatanHarian, setDataKegiatanHarian] = useState([]);
  const [dateKegiatanHarian, setDateKegiatanHarian] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [dataPatroli, setDataPatroli] = useState([]);
  const [datePatroli, setDatePatroli] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  // Get data tabel
  useEffect(() => {
    const getDataKegiatanHarian = async () => {
      const res = await api.kegiatanHarianDate({
        startdate: dateKegiatanHarian.startDate,
        enddate: dateKegiatanHarian.endDate
      });
      setDataKegiatanHarian(res.data.Data);
    };
    const getDataPatroli = async () => {
      const res = await api.patroliHarianDate();
      setDataPatroli(res.data.Data);
    };
    getDataKegiatanHarian();
    getDataPatroli();
  }, [dateKegiatanHarian, datePatroli]);

  const device = detectDevice();
  console.log('ini device :', device);

  return (
    <>
      {/* Map */}
      <div className=" px-4 md:px-10 mx-auto w-full -mt-8">
        <div className="w-full bg-gray-50 rounded-lg shadow-black/20 shadow-lg">
          <MapBox height="50vh" data={satpamData} />
        </div>
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 my-4 justify-between w-full">
          <div className="w-full h-auto bg-gray-50 rounded-lg shadow-black/20 shadow-lg">
            {/* <MapBox height="385px" data={satpamData} /> */}
            {/* {console.log(dataKegiatanHarian)} */}
            {/* {dataKegiatanHarian.length > 0 && ( */}
            <Grafik
              title="Grafik Kegiatan Harian"
              grafikData={dataKegiatanHarian}
              label="Data kegiatan harian"
              date={dateKegiatanHarian}
              setDate={setDateKegiatanHarian}
            />
            {/* // )} */}
          </div>
          <div className="w-full h-auto bg-gray-50 rounded-lg shadow-black/20 shadow-lg">
            {/* {dataPatroli.length > 0 && ( */}
            <Grafik
              title="Grafik Patroli"
              grafikData={dataPatroli}
              label="Data Patroli"
              date={datePatroli}
              setDate={setDatePatroli}
            />
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
}
