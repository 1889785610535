import moment from 'moment';
import React from 'react';
import Headerlaporan from '../general/HeaderLaporan';
import TableRiwayatPatroli from './TableRiwayatPatroli';

export default function TableCetakPatroliPerPat({ dataPatroli, ruteDetail }) {
  // console.log(ruteDetail);
  return (
    <div className="w-full">
      <div className="flex w-full">
        <Headerlaporan />
        <div className="w-full px-5 bg-black flex flex-col items-end justify-center text-white">
          <div className="text-[18px] font-bold">LAPORAN PATROLI</div>
          <div className="text-[14px]">{dataPatroli.satpamperusahaannama}</div>
        </div>
      </div>
      <div className="m-5">
        <table className="border-gray-50 w-full" id="kegiatanHarianPrint">
          <thead className="bg-primary font-bold text-white text-[12px]">
            <tr>
              <th colSpan="3" className="border border-gray-300 py-2">
                <div className="flex justify-between px-2 uppercase">
                  <p className="font-semibold text-[18px]">#{dataPatroli?.satpampatrolikode}</p>
                  <p className="font-semibold text-[18px]">{dataPatroli?.satpampatrolijenis}</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-black">
            <tr>
              <td
                colSpan={2}
                className="w-1/4 !h-[8px] border border-gray-300 px-2 py-2 font-semibold">
                <div className="flex gap-2 justify-between uppercase">
                  <p>NAMA PERSONIL</p>
                  <p>{dataPatroli?.satpamnamalengkap}</p>
                </div>
              </td>
              <td
                rowSpan={dataPatroli?.satpampatrolijenis === 'Patroli Berkendara' ? 6 : 2}
                className=" w-1/5 px-2 border border-gray-300 py-2 text-left">
                <h4 className="font-semibold mb-2 uppercase">Rute Patroli</h4>
                <div className="w-full overflow-x-auto">
                  <table className="table-auto leading-normal w-full">
                    <thead>
                      <tr>
                        <th
                          colSpan={3}
                          className="px-2 py-1 border align-middle border-gray-200 bg-primary text-white text-sm font-semibold text-center tracking-wider">
                          {dataPatroli?.satpamposnama}
                        </th>
                      </tr>
                      <tr>
                        <th className="px-2 py-1 border align-middle border-gray-200 text-sm font-semibold text-center tracking-wider">
                          CHECKPOINT
                        </th>
                        <th className="px-2 py-1 border align-middle border-gray-200 text-sm font-semibold text-center tracking-wider">
                          TARGET
                        </th>
                        <th className="px-2 py-1 border align-middle border-gray-200 text-sm font-semibold text-center tracking-wider">
                          KEMAJUAN
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataPatroli?.Detail?.length > 0 &&
                        dataPatroli?.Detail?.map((item) => (
                          <tr key={item.satpampatrolidetailsatpamtitikkode}>
                            <td className="px-2 py-1 border border-gray-200 bg-white text-sm">
                              <p className="text-center">{item.satpamtitiknama}</p>
                            </td>
                            <td className="px-2 py-1 border border-gray-200 bg-white text-sm">
                              <p className="text-center">{item.satpampatrolidetailtarget}</p>
                            </td>
                            <td className="px-2 py-1 border border-gray-200 bg-white text-sm">
                              <p className="text-center">
                                {Number.isInteger(parseFloat(item.presentase))
                                  ? parseFloat(item.presentase) + ' %'
                                  : parseFloat(item.presentase).toFixed(3) + ' %'}
                              </p>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td className=" border border-gray-300 px-2 py-2 font-semibold whitespace-nowrap">
                <div className="flex gap-2 justify-between items-center">
                  <p>WAKTU MULAI</p>
                  <p className="text-primary">
                    {dataPatroli.satpampatroliawalwaktu !== null
                      ? moment(dataPatroli.satpampatroliawalwaktu).format('DD MMM YYYY HH:mm')
                      : '-'}
                  </p>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <p>WAKTU AKHIR</p>
                  <p className="text-primary">
                    {dataPatroli.satpampatroliakhirwaktu !== null
                      ? moment(dataPatroli.satpampatroliakhirwaktu).format('DD MMM YYYY HH:mm')
                      : '-'}
                  </p>
                </div>
                <div className="flex gap-2 justify-between items-center">
                  <p>WAKTU PATROLI</p>
                  <p>{dataPatroli?.durasi_kumulatif}</p>
                </div>
              </td>
              <td
                className=" border border-gray-300 px-2 py-2 font-semibold align-top"
                height="5px">
                <div className="flex gap-2 justify-between">
                  <p>TARGET</p>
                  <p>{dataPatroli?.target_kumulatif}X</p>
                </div>
                <div className="flex gap-2 justify-between">
                  <p>KEMAJUAN</p>
                  <p>{dataPatroli?.persentase_kumulatif?.toFixed()}%</p>
                </div>
              </td>
            </tr>
            {dataPatroli?.satpampatrolijenis === 'Patroli Berkendara' && (
              <>
                <tr>
                  <td
                    colSpan={2}
                    className=" border border-gray-300 px-2 py-2 font-semibold"
                    height="5px">
                    <div className="flex gap-2 justify-between">
                      <p>JENIS KENDARAAN</p>
                      <p>{dataPatroli?.satpampatrolikendaraanjenis}</p>
                    </div>
                    <div className="flex gap-2 justify-between">
                      <p>NAMA KENDARAAN</p>
                      <p>{dataPatroli?.satpampatrolikendaraannama}</p>
                    </div>
                    <div className="flex gap-2 justify-between">
                      <p>NOMOR KENDARAAN</p>
                      <p>{dataPatroli?.satpampatrolikendaraannomor}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className=" border border-gray-300 px-2 py-2 font-semibold" height="5px">
                    <p className="text-center">KILOMETER AWAL</p>
                  </td>
                  <td className=" border border-gray-300 px-2 py-2 font-semibold" height="5px">
                    <p className="text-center">KILOMETER AKHIR</p>
                  </td>
                </tr>
                <tr>
                  <td className="w-1/4 border border-gray-300 px-2 py-2 font-semibold" height="5px">
                    <div className="flex gap-2 justify-arround items-center px-4">
                      <div className="w-1/2">{dataPatroli?.satpampatrolikilometerawalangka}</div>
                      {dataPatroli?.satpampatrolikilometerawalfoto.split('/data-patroli/')[1] && (
                        <div className="flex w-1/2">
                          <div className="w-full">
                            <div className="h-24 w-full bg-white rounded-md border border-gray-200 shadow-md break-all">
                              <img
                                className="rounded h-24 object-cover w-full"
                                src={dataPatroli?.satpampatrolikilometerawalfoto}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="w-1/4 border border-gray-300 px-2 py-2 font-semibold" height="5px">
                    <div className="flex gap-2 justify-arround items-center px-4">
                      <div className="w-1/2">{dataPatroli?.satpampatrolikilometerakhirangka}</div>
                      {dataPatroli?.satpampatrolikilometerakhirfoto.split('/data-patroli/')[1] && (
                        <div className="flex w-1/2">
                          <div className="w-full">
                            <div className="h-24 w-full bg-white rounded-md border border-gray-200 shadow-md break-all">
                              <img
                                className="rounded h-24 object-cover w-full"
                                src={dataPatroli?.satpampatrolikilometerakhirfoto}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={2}
                    className=" border border-gray-300 px-2 py-2 font-semibold"
                    height="5px">
                    <div className="flex gap-2 justify-between">
                      <p>JARAK (KM AWAL - KM AKHIR)</p>
                      <p>
                        {dataPatroli?.satpampatrolikilometerakhirangka -
                          dataPatroli?.satpampatrolikilometerawalangka}{' '}
                        KM
                      </p>
                    </div>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td colSpan={3} className="min-w-full border border-gray-300 px-2 py-2" height="5px">
                <h4 className="font-semibold my-2 text-center">RIWAYAT PATROLI</h4>
                {/* <TimelinePatroli data={data ? data : ''} /> */}
                <TableRiwayatPatroli rutePatroli={dataPatroli?.Detail} ruteDetail={ruteDetail} />
                <div className="page-break" />
              </td>
            </tr>
            <tr>
              <td
                colSpan="2"
                className="border border-gray-300 px-2 py-2 text-center align-top"></td>
              <td className="border border-gray-300 px-2 py-2 text-center align-middle">
                <div>Tanda Tangan</div>
                <div className="border mt-16 mb-5 mx-5" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-center w-full my-2 text-black">
        <b>Copyright@{moment().year()}</b>
        <br />
        <b>PT. Kalpika Loka Persada</b>
      </div>
    </div>
  );
}
