import React, { useState, useEffect } from 'react';
import * as api from '../../redux/api';
import Swal from 'sweetalert2';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { satpamUpdateSchema } from '../../utils/schemaValidation';
import useImagePreview from '../../hooks/useImagePreview';
import imageCompression from 'browser-image-compression';
import { ClipLoader } from 'react-spinners';

export default function SatpamUpdate() {
  const [satpamKode, setSatpamKode] = useState('');
  const [satpamMenuMaster, setSatpamMenuMaster] = useState([]);
  const [satpamMenu, setSatpamMenu] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(satpamUpdateSchema)
  });

  useEffect(() => {
    const getSatpamById = async () => {
      await api.satpamById(id).then((res) => {
        setSatpamKode(res.data.data[0].satpamkode);
        const defaultValue = {
          satpamnip: res.data.data[0].satpamnip !== null ? res.data.data[0].satpamnip : '',
          satpamstatus: res.data.data[0].satpamstatus !== null ? res.data.data[0].satpamstatus : '',
          satpamnamalengkap:
            res.data.data[0].satpamnamalengkap !== null ? res.data.data[0].satpamnamalengkap : '',
          satpamnohp: res.data.data[0].satpamnohp !== null ? res.data.data[0].satpamnohp : '',
          satpamemail: res.data.data[0].satpamemail !== null ? res.data.data[0].satpamemail : '',
          satpamjabatan:
            res.data.data[0].satpamjabatan !== null ? res.data.data[0].satpamjabatan : '',
          satpamtipe: res.data.data[0].satpamtipe !== null ? res.data.data[0].satpamtipe : '',
          satpamjeniskelamin: res.data.data[0].satpamjeniskelamin,
          satpampendidikanjenjang:
            res.data.data[0].satpampendidikanjenjang !== null
              ? res.data.data[0].satpampendidikanjenjang
              : '',
          satpambanknama:
            res.data.data[0].satpambanknama !== null ? res.data.data[0].satpambanknama : '',
          satpambanknomorrekening:
            res.data.data[0].satpambanknomorrekening !== null
              ? res.data.data[0].satpambanknomorrekening
              : '',
          satpamfoto:
            res.data.data[0].satpamfoto !== null || res.data.data[0].satpamfoto !== ''
              ? `${process.env.REACT_APP_API_URL}/assets/upload/data-foto/${res.data.data[0].satpamfoto}`
              : '',
          satpambankfoto:
            res.data.data[0].satpambankfoto !== null || res.data.data[0].satpambankfoto !== ''
              ? `${process.env.REACT_APP_API_URL}/assets/upload/data-bank/${res.data.data[0].satpambankfoto}`
              : ''
        };
        reset(defaultValue);
      });
    };
    const getSatpamMenuMaster = async () => {
      await api.satpamMenuMaster().then((res) => {
        console.log(res.data.data);
        setSatpamMenuMaster(res.data.data);
      });
    };
    getSatpamMenuMaster();
    getSatpamById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getSatpamMenu = async () => {
      await api.satpamMenu({ satpamkode: satpamKode }).then((res) => {
        if (res.data.data.length > 0) {
          res.data.data.map((menu) => {
            return setSatpamMenu((prev) => [...prev, { satpammenukode: menu.satpammenukode }]);
          });
        } else {
          setSatpamMenu([]);
        }
      });
    };
    getSatpamMenu();
  }, [satpamKode]);

  const compressOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const [fotoSatpamPreview] = useImagePreview(watch('satpamfoto'));
  const [fotoBankSatpamPreview] = useImagePreview(watch('satpambankfoto'));

  useEffect(() => {
    console.log(satpamMenu);
  }, [satpamMenu]);

  const handleSatpamMenu = (e) => {
    if (e.target.checked) {
      setSatpamMenu([...satpamMenu, { satpammenukode: e.target.value }]);
    } else {
      setSatpamMenu(satpamMenu.filter((menu) => menu.satpammenukode !== e.target.value));
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    data.satpamid = id;
    data.satpamkode = satpamKode;

    const formData = new FormData();
    formData.append('satpammenu', JSON.stringify(satpamMenu));

    if (data.satpamfoto.length > 0 && typeof data.satpamfoto !== 'string') {
      const compressedFile = await imageCompression(data.satpamfoto[0], compressOptions);
      formData.append('satpamfoto', compressedFile, compressedFile.name);
    }

    if (data.satpambankfoto.length > 0 && typeof data.satpambankfoto !== 'string') {
      const compressedFile = await imageCompression(data.satpambankfoto[0], compressOptions);
      formData.append('satpambankfoto', compressedFile, compressedFile.name);
    }

    for (const key in data) {
      if (key === 'field') {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    await api
      .satpamUpdate(formData)
      .then((res) => {
        Swal.fire('Good job!', 'You successfully submit the form!', 'success');
        setLoading(false);
        navigate('/personil/data');
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      });
  };

  return (
    <div className="relative px-4 md:px-10 mx-auto w-full -mt-8">
      <div className="w-full">
        <div className="w-full py-3 bg-gray-50 rounded-lg shadow-black/20 shadow-lg flex justify-center items-center mb-10 text-lg text-center font-semibold">
          Update Personil
        </div>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="w-full">
          <div className="flex flex-wrap md:flex-nowrap gap-5 text-sm">
            <div className="w-full md:w-1/2 flex flex-col gap-5">
              <div className="bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex mb-3">
                  <div className="md:w-1/2">
                    <label htmlFor="satpamnip" className="block font-semibold mb-1 md:mb-0">
                      NIP
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      type="text"
                      id="satpamnip"
                      className={`w-full text-sm ${errors.satpamnip ? 'invalidForm' : 'inputForm'}`}
                      {...register('satpamnip')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamnip?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3">
                  <div className="md:w-1/2">
                    <label htmlFor="satpamnamalengkap" className="block font-semibold mb-1 md:mb-0">
                      Nama Lengkap
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      type="text"
                      id="satpamnamalengkap"
                      className={`w-full text-sm ${
                        errors.satpamnamalengkap ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamnamalengkap')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamnamalengkap?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3">
                  <div className="md:w-1/2">
                    <label
                      htmlFor="satpamjeniskelamin"
                      className="block font-semibold mb-1 md:mb-0">
                      Jenis Kelamin
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <select
                      id="satpamjeniskelamin"
                      className={`w-full text-sm ${
                        errors.satpamjeniskelamin ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamjeniskelamin')}>
                      <option value="">Pilih jenis kelamin</option>
                      <option value="Laki-Laki">Laki-laki</option>
                      <option value="Perempuan">Perempuan</option>
                    </select>
                    <p className="text-errorText text-xs">{errors.satpamjeniskelamin?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3">
                  <div className="md:w-1/2">
                    <label htmlFor="satpamnohp" className="block font-semibold mb-1 md:mb-0">
                      Nomor HP
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      type="text"
                      className={`w-full text-sm ${
                        errors.satpamnohp ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamnohp')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamnohp?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3">
                  <div className="md:w-1/2">
                    <label htmlFor="satpamemail" className="block font-semibold mb-1 md:mb-0">
                      Email
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      type="email"
                      className={`w-full text-sm ${
                        errors.satpamemail ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamemail')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamemail?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3">
                  <div className="md:w-1/2">
                    <label
                      htmlFor="satpampendidikanjenjang"
                      className="block font-semibold mb-1 md:mb-0">
                      Jenjang Pendidikan
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <select
                      id="satpampendidikanjenjang"
                      className={`w-full text-sm ${
                        errors.satpampendidikanjenjang ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpampendidikanjenjang')}>
                      <option value="">Pilih jenjang pendidikan</option>
                      <option value="Gada Utama">Gada Utama</option>
                      <option value="Gada Madya">Gada Madya</option>
                      <option value="Gada Pratama">Gada Pratama</option>
                    </select>
                    <p className="text-errorText text-xs">
                      {errors.satpampendidikanjenjang?.message}
                    </p>
                  </div>
                </div>
                <div className="md:flex mb-3">
                  <div className="md:w-1/2">
                    <label htmlFor="satpamstatus" className="block font-semibold mb-1 md:mb-0">
                      Status
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <select
                      id="satpamstatus"
                      className={`w-full text-sm ${
                        errors.satpamstatus ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamstatus')}>
                      <option value="">Pilih status</option>
                      <option value="Aktif">Aktif</option>
                      <option value="Tidak Aktif">Tidak Aktif</option>
                    </select>
                    <p className="text-errorText text-xs">{errors.satpamstatus?.message}</p>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2">
                    <label htmlFor="satpamfoto" className="block font-semibold mb-1 md:mb-0">
                      Foto Profil
                    </label>
                  </div>
                  <div className="flex flex-wrap md:w-1/2 w-full">
                    <div className="w-full">
                      <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                        <div className="flex flex-col w-full">
                          <label className="p-2 flex flex-col w-full h-28 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            {fotoSatpamPreview ? (
                              <div className="flex flex-col items-center justify-center">
                                <img
                                  src={fotoSatpamPreview}
                                  className="w-full h-24 object-contain"
                                  alt="preview"
                                />
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-2">
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor">
                                  <path
                                    fillRule="evenodd"
                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                    clipRule="evenodd"
                                  />
                                </svg> */}
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                  Select a photo
                                </p>
                              </div>
                            )}
                            <input
                              type="file"
                              className="opacity-0"
                              {...register('satpamfoto')}
                              accept="image/*"
                            />
                          </label>
                        </div>
                        <p className="text-errorText text-xs">{errors.satpamfoto?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex mb-3 justify-arround items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="satpamjabatan" className="block font-semibold mb-1 md:mb-0">
                      Jabatan
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      type="text"
                      className={`w-full text-sm ${
                        errors.satpamjabatan ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamjabatan')}
                    />
                    <p className="text-errorText text-xs">{errors.satpamjabatan?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3 justify-arround items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="satpamtipe" className="block font-semibold mb-1 md:mb-0">
                      Tipe
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <select
                      className={`w-full text-sm cursor-pointer ${
                        errors.satpamtipe ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpamtipe')}>
                      <option value="">Pilih tipe personil</option>
                      <option value="Utama">Utama</option>
                      <option value="Cadangan">Cadangan</option>
                    </select>
                    <p className="text-errorText text-xs">{errors.satpamtipe?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3 justify-arround items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="satpambanknama" className="block font-semibold mb-1 md:mb-0">
                      Nama Akun Bank
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      type="text"
                      className={`w-full text-sm ${
                        errors.satpambanknama ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpambanknama')}
                    />
                    <p className="text-errorText text-xs">{errors.satpambanknama?.message}</p>
                  </div>
                </div>
                <div className="md:flex mb-3 justify-arround items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label
                      htmlFor="satpambanknomorrekening"
                      className="block font-semibold mb-1 md:mb-0">
                      No. Rekening Bank
                    </label>
                  </div>
                  <div className="md:w-1/2">
                    <input
                      type="text"
                      className={`w-full text-sm ${
                        errors.satpambanknomorrekening ? 'invalidForm' : 'inputForm'
                      }`}
                      {...register('satpambanknomorrekening')}
                    />
                    <p className="text-errorText text-xs">
                      {errors.satpambanknomorrekening?.message}
                    </p>
                  </div>
                </div>
                <div className="md:flex justify-arround items-center gap-3 px-2 md:px-5">
                  <div className="md:w-1/2">
                    <label htmlFor="satpamfoto" className="block font-semibold mb-1 md:mb-0">
                      Foto Rekening Bank
                    </label>
                  </div>
                  <div className="flex flex-wrap md:w-1/2 w-full">
                    <div className="w-full p-1 md:p-2">
                      <div className="h-auto p-3 bg-white rounded-md border border-gray-200 shadow-md">
                        <div className="flex flex-col w-full">
                          <label className="p-2 flex flex-col w-full h-28 items-center justify-center border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            {fotoBankSatpamPreview ? (
                              <div className="flex flex-col items-center justify-center">
                                <img
                                  src={fotoBankSatpamPreview}
                                  className="w-full h-24 object-contain"
                                  alt="preview"
                                />
                              </div>
                            ) : (
                              <div className="flex flex-col items-center justify-center pt-2">
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                  viewBox="0 0 20 20"
                                  fill="currentColor">
                                  <path
                                    fillRule="evenodd"
                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                    clipRule="evenodd"
                                  />
                                </svg> */}
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                  Select a photo
                                </p>
                              </div>
                            )}
                            <input
                              type="file"
                              className="opacity-0"
                              {...register('satpambankfoto')}
                              accept="image/*"
                            />
                          </label>
                        </div>
                        <p className="text-errorText text-xs">{errors.satpambankfoto?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/2 flex flex-col gap-5">
              <div className="bg-gray-50 p-5 rounded-lg shadowAll flex flex-col justify-around">
                <div className="md:flex flex-col">
                  <label className="block font-semibold mb-2">Menu Satpam</label>
                  {satpamMenuMaster?.map((menumaster) => (
                    <div
                      key={menumaster.satpammenukode}
                      className="flex flex-row flex-wrap p-1.5 text-sm">
                      <div className="flex gap-2 pr-2">
                        <input
                          type="checkbox"
                          id={menumaster.satpammenukode}
                          value={menumaster.satpammenukode}
                          onChange={handleSatpamMenu}
                          checked={
                            satpamMenu.filter(
                              (menu) => menu.satpammenukode === menumaster.satpammenukode
                            ).length > 0
                              ? true
                              : false
                          }
                        />
                        <label htmlFor={menumaster.satpammenukode} className=" cursor-pointer">
                          {menumaster.satpammenunama?.replace('<br>', ' ')}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center w-full py-5 px-10 my-8 shadowAll rounded-lg">
            <button
              type="submit"
              className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80"
              disabled={loading === true ? true : false}>
              {loading ? (
                <ClipLoader color="white" loading={true} size={20} speedMultiplier={0.7} />
              ) : (
                'Simpan'
              )}
            </button>
            <Link to="/personil/data">
              <button className="text-gray-50 py-2 px-5 rounded-md text-sm bg-dark hover:opacity-80">
                Kembali
              </button>
            </Link>
            {/* </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}
